<template>
  <div class="AddExpressModal" @click="addExpress">
    <slot name="default">
      <!-- <img class="default-icon" src="@/assets/supplier/images/deliveryManage/express-add-icon.png" /> -->
    </slot>
    <CustomModal v-model:modalVisible="visible" :modalTitle="modalTitle" v-model:submitting="submittingFlag" @cancel="cancel" @submit="modelSubmit">
      <a-form ref="addExpressFormRef" :model="addExpressForm" :rules="addExpressRules" :labelCol="{ style: { width: locale == 'en_US' ? '155px' : '82px' } }"> <!--  :labelCol="{ span: locale == 'en_US' ? 8 : 5 }"  -->
        <a-form-item name="expressName" :label="t('components.AddExpressModal.expressName')">
          <a-input allowClear size="large" v-model:value="addExpressForm.expressName" :placeholder="t('components.AddExpressModal.expressNameValidatorText')"></a-input>
          <!-- <a-select v-model:value="addExpressForm.expressName" allowClear size="large" placeholder="快递公司名称">
            <a-select-option v-for="item in expressCompany" :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
          </a-select> -->
        </a-form-item>
        <a-form-item name="expressCode" :label="t('components.AddExpressModal.expressCode')">
          <a-input allowClear size="large" v-model:value="addExpressForm.expressCode" :placeholder="t('components.AddExpressModal.expressCodeValidatorText')"></a-input>
          <!-- <a-select v-model:value="addExpressForm.expressCode" allowClear size="large" placeholder="单号">
            <a-select-option v-for="item in expressCompany" :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
          </a-select> -->
        </a-form-item>
        <a-form-item name="remark" :label="t('components.AddExpressModal.remark')">
          <a-textarea v-model:value.trim="addExpressForm.remark" allowClear size="large" :auto-size="{ minRows: 2, maxRows: 5 }" :placeholder="t('components.AddExpressModal.remarkValidatorText')" />
        </a-form-item>
      </a-form>
    </CustomModal>
  </div>
</template>

<script>
/* eslint-disable */
import { ref, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import CustomModal from '@/views/supplier/components/CustomModal';
export default {
  name: 'deliveryManageDetail',
  components: {
    CustomModal,
  },
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    submitting: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modalVisible', 'submit', 'update:submitting'],
  setup(props, { emit }) {
    const { t, locale } = useI18n();
    console.log('locale :>> 1111', locale.value);
    const visible = ref(false);
    watch(
      () => props.modalVisible,
      (newValue) => {
        visible.value = newValue;
      }
    );
    watch(
      () => visible.value,
      (newValue) => {
        emit('update:modalVisible', newValue);
      }
    );
    function addExpress() {
      // modalVisible.value = true;
      emit('update:modalVisible', true);
    }
    const modalTitle = ref(t('components.AddExpressModal.title'));
    const expressCompany = [
      {
        label: 'a',
        value: 1,
      },
      {
        label: 'b',
        value: 2,
      },
    ];
    const addExpressFormRef = ref()
    const addExpressForm = reactive({
      expressName: '',
      expressCode: '',
      remark: '',
    });
    const addExpressRules = {
      expressName: [
        {
          required: true,
          validator: (rule, value) => {
            if (value === '') return Promise.reject(t('components.AddExpressModal.expressNameValidatorText'));
            return Promise.resolve();
          },
          trigger: ['change', 'blur'],
        },
      ],
      expressCode: [
        {
          required: true,
          validator: (rule, value) => {
            if (value === '') return Promise.reject(t('components.AddExpressModal.expressCodeValidatorText'));
            return Promise.resolve();
          },
          trigger: ['change', 'blur'],
        },
      ],
      // remark: [
      //   {
      //     required: true,
      //     validator: (rule, value) => {
      //       if (value === '') return Promise.reject(t('components.AddExpressModal.remarkValidatorText'));
      //       return Promise.resolve();
      //     },
      //     trigger: 'blur',
      //   },
      // ],
    };
    const submittingFlag = ref(false);
    watch(
      () => props.submitting,
      (newValue) => {
        submittingFlag.value = newValue;
      }
    );
    watch(
      () => submittingFlag.value,
      (newValue) => {
        cancel()
        emit('update:submitting', newValue);
      }
    );
    function cancel() {
      // addExpressForm.expressName = ''
      // addExpressForm.expressCode = ''
      // addExpressForm.remark = ''
      addExpressFormRef.value.resetFields()
    }
    function modelSubmit() {
      addExpressFormRef.value.validate().then((res) => {
        emit('submit', { ...addExpressForm })
      }).catch((err) => {})
    }

    return {
      t,
      locale,
      visible,
      addExpress,
      modalTitle,
      expressCompany,
      addExpressFormRef,
      addExpressForm,
      addExpressRules,
      submittingFlag,
      cancel,
      modelSubmit,
    };
  },
};
</script>

<style lang="less">
.AddExpressModal {
  display: inline-block;
  cursor: pointer;
  .default-icon {
    width: 24px;
  }
}
</style>
